import { ControlStack } from '../layout'

const PageHeaderTools = (props) => {
  return (
    <ControlStack gap='var(--pdg-control-l)'>
      { props.children }
    </ControlStack>
  )
}

export default PageHeaderTools
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { Amplify, Storage } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import awsExports from './aws-exports'
import '@aws-amplify/ui-react/styles.css'

import { Layout } from './layout'
import { LoadingSpinner } from './components'
import { ContextProvider, ErrorBoundary } from './utils'

import './design'

const Backend = React.lazy(() => import('./routes/backend/Backend'))
const Charts = React.lazy(() => import('./routes/charts/Charts'))
const Clubs = React.lazy(() => import('./routes/clubs/Clubs'))
const Dashboard = React.lazy(() => import('./routes/dashboard/Dashboard'))
const Trends = React.lazy(() => import('./routes/trends/Trends'))
const Users = React.lazy(() => import('./routes/users/Users'))

Amplify.configure(awsExports)

const App = () => {

  const wrapLazy = (element) => {
    return (
      <React.Suspense fallback={<LoadingSpinner />}>
        { element }
      </React.Suspense>
    )
  }

  const routes =
    <React.Fragment>
      <Route path='backend' element={wrapLazy(<Backend />)} />
      <Route path='charts' element={wrapLazy(<Charts />)} />
      <Route path='clubs' element={wrapLazy(<Clubs />)} />
      <Route path='dashboard' element={wrapLazy(<Dashboard />)} />
      <Route path='trends' element={wrapLazy(<Trends />)} />
      <Route path='users' element={wrapLazy(<Users />)} />
      <Route index element={<Navigate to='/dashboard' />} />
    </React.Fragment>

  return (
    <Authenticator variation='modal' hideSignUp={true}>
      {({ signOut, user }) => (
        <ErrorBoundary>
          <React.Suspense>
            <ContextProvider>
              <Routes>
                <Route path='/' element={<Layout />}>
                  { routes }
                </Route>
              </Routes>
            </ContextProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </Authenticator>
  )
}

export default App
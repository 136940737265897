import Styled from 'styled-components'

const StyledStackLayout = Styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap};
`

const StackLayout = (props) => {
  return (
    <StyledStackLayout gap={props.gap}> 
      { props.children } 
    </StyledStackLayout>
  )
}

export default StackLayout
import { createContext, useState } from 'react'
import { API } from 'aws-amplify'

import * as Query from '../api/queries'

import { useAlert } from '../hooks'
import { User } from '../classes'
import { Constant } from '../utils'

export const UserContext = createContext({ 
  users: null,
  listUsers: () => {},
  setUsers: () => {}
})

export const UserProvider = (props) => {

  const { handleError } = useAlert()
  
  const [ users, setUsers ] = useState(null)
  const [ timeout, setTimeout ] = useState(Date.now())

  const listUsers = async () => {

    if (timeout > Date.now()) { return }

    setTimeout(Date.now() + Constant.reloadFreq)
    console.log('API: listing users')

    var users = []
    var nextToken = null
    var maxIter = 100

    try {
      do {
        console.log('Fetching users...')
        const result = await API.graphql({ 
          query: Query.listUsers, 
          variables: { limit: 300, nextToken: nextToken } 
        })
        users = users.concat(result.data.listUsers.items.map(obj => new User(obj)))
        nextToken = result.data.listUsers.nextToken
        console.log('Successfully fetched users: ', users.length)
        maxIter = maxIter - 1
      } while (nextToken != null && maxIter > 0)
    }
    catch (error) {
      handleError(error)
    }

    setUsers(users.sort((x,y) => User.sortByDate(x,y)))
  }

  /*
  const fecthLocations = async () => {

    async function fetchLocation(ip) {
      const myHeaders = new Headers();
      myHeaders.append("apikey", "SV3gySXWK2wdN2Vu0y52zChGWEiIPCnP");
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };
      const response = await fetch(`https://api.apilayer.com/ip_to_location/${ip}`, requestOptions);
      const result = await response.text();
      return result; // oder entsprechend umwandeln, je nachdem wie die API die Daten zurückgibt
    }

    for (const user of users) {
      if (user.ip) {
        try {
          const location = JSON.parse(await fetchLocation(JSON.parse(user.ip)[0]));
          user.location = location;
        } catch (error) {
          console.log('Fehler beim Abrufen des Standorts für IP:', user.ip, error);
        }
      }
    }
  }
  */

  const value = { 
    users,
    listUsers,
    setUsers
  }

  return (
    <UserContext.Provider value={value}>
      { props.children }
    </UserContext.Provider>
  )
}
import React, { useState } from 'react'
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons'
import Styled from 'styled-components'

import { Alert } from '../components'
import { PageHeader } from '../layout'

const Aside = Styled.aside`
  position: relative;
  overflow-y: scroll;
  width: fit-content;
  min-width: 200px;
  max-width: 400px;
  padding: var(--pdg-container);
  padding-top: var(--pdg-component);
  border-right: 1px solid var(--clr-border);
  @media (max-width: 720px) {
    overflow-y: ${props => props.active ? 'scroll' : 'hidden'};
    width: 100%;
    max-width: 100%;
    max-height: ${props => props.active ? null : 'calc( 2 * var(--pdg-component) + 20px )'};
    padding-right: calc( 2 * var(--pdg-container) + 20px );
    border-right: none;
    border-bottom: 1px solid var(--clr-border);
  }
`
const AsideButton = Styled.button`
  display: none;
  position: absolute;
  height: 20px;
  top: var(--pdg-component);
  right: var(--pdg-container);
  background: none;
  @media (max-width: 720px) {
    display: block;
  }
`
const ScrollContainer = Styled.main`
  overflow-y: scroll;
  scrollbar-width: none;
  padding: var(--pdg-container);
  padding-top: var(--pdg-component);
  flex: 1;
`
const Container = Styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`

const PageLayout = (props) => {

  const [ asideActive, setAsideActive ] = useState(true)

  const toggleAsideActive = () => setAsideActive(!asideActive)

  return (
    <React.Fragment>
      <Alert />
      <PageHeader
        exitTo={props.exitTo}
        exitTitle={props.exitTitle}
        title={props.title}
        tools={props.tools}
      />
      <Container>
        { !props.aside ? null :
          <Aside active={asideActive}> 
            <AsideButton onClick={toggleAsideActive}> 
              { asideActive ? <CaretUpFill /> : <CaretDownFill /> }
            </AsideButton>
            { asideActive ? props.aside : <h4> { props.asideTitle } </h4> } 
          </Aside>
        }
        <ScrollContainer> { props.children } </ScrollContainer>
      </Container>
    </React.Fragment>
  ) 
}

export default PageLayout
import { Search } from 'react-bootstrap-icons'
import Styled from 'styled-components'
import { Icon } from '../components'

const StyledSearchBar = Styled.div`
  transition: var(--hvr-transition);
  display: flex;
  gap: var(--spc-s);
  padding-inline: var(--pdg-control-l);
  width: 300px;
  height: var(--siz-control-l);
  box-shadow: var(--sdw-component);
  border-radius: var(--rds-control);
  background: var(--clr-component);
  &:hover, &:focus {
    transform: var(--hvr-transform);
  }
  @media (max-width: 720px) {
    display: none;
  }
`

const SearchBar = (props) => {

  const onChange = (event) => {
    props.onChange(event.target.value, props.path)
  }

  return (
    <StyledSearchBar>
      <Icon type='search' svg={<Search />} />
      <input type='text' placeholder={props.placeholder} onChange={onChange} />
    </StyledSearchBar>
  ) 
}

export default SearchBar
import styled from 'styled-components'
import { useAlert } from '../hooks'

const styles = {
  'success': {
    background: 'var(--clr-green)',
    color: 'var(--clr-white)',
  },
  'failure': {
    background: 'var(--clr-red)',
    color: 'var(--clr-white)',
  },
}

const StyledAlert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: var(--pdg-container);
  right: var(--pdg-container);
  min-width: 200px;
  height: var(--siz-control-l);
  padding-inline: var(--pdg-control-l);
  border-radius: var(--rds-control);
  box-shadow: var(--sdw-component);
  z-index: 999;
  background: ${props => props.background};
  color: ${props => props.color};
  font-weight: bold;
` 

const Alert = () => {

  const { message, style } = useAlert()

  if (message) {
    return (
      <StyledAlert style={styles[style]}>
        { message }
      </StyledAlert>
    ) 
  } else {
    return <></>
  }
}

export default Alert

export const listClubs = /* GraphQL */ `
  query ListClubs($id: ID, $limit: Int, $nextToken: String) {
    listClubs(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        admins
        cashiers
        readers
        reporters
        owner
        ownerSystem
        maxMemberCount
        premiumAboState
        isPremiumTill
        info {
          name
        }
        Members {
          items {
            id
          }
        }
        Reports {
          items {
            id
          }
        }
        Transactions {
          items {
            id
          }
        }
      }
      nextToken
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers($id: ID, $limit: Int, $nextToken: String) {
    listUsers(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        email
      }
      nextToken
    }
  }
`
import { useContext } from 'react'
import { AlertContext } from '../contexts/AlertContext'
import { AnalyticsContext } from '../contexts/AnalyticsContext'
import { BucketContext } from '../contexts/BucketContext'
import { ClubContext } from '../contexts/ClubContext'
import { UserContext } from '../contexts/UserContext'

export { default as useDataFilter } from './useDataFilter'
export { default as useTrends } from './useTrends'

export const useAnalytics = () => useContext(AnalyticsContext)
export const useAlert = () => useContext(AlertContext)
export const useBucket = () => useContext(BucketContext)
export const useClub = () => useContext(ClubContext)
export const useUser = () => useContext(UserContext)
import { createContext, useEffect } from 'react'

import { useClub, useUser } from '../hooks'

export const AnalyticsContext = createContext({ 
  clubs: null,
  users: null,
})

export const AnalyticsProvider = (props) => {
  
  const { clubs, listClubs, setClubs } = useClub()
  const { users, listUsers, setUsers } = useUser()

  var value = {}

  useEffect(() => { listClubs(); listUsers() }, [])

  if (clubs != null && users != null) {

    var clubData = clubs
    var userData = users

    for (var club of clubData) {
      const owners = users.filter(obj => obj.id == club.owner)
      club.ownerEmail = owners.length > 0 ? owners[0].email : ""
    }

    for (var user of userData) {
      const data = clubs.filter(obj => obj.hasUser(user.id))
      user.clubs = data.map(obj => obj.name)
    }

    if (clubData != clubs) setClubs(clubData)
    if (userData != users) setUsers(userData)

    value = { clubs, users }
  }

  return (
    <AnalyticsContext.Provider value={value}>
      { props.children }
    </AnalyticsContext.Provider>
  )
}
import Styled from 'styled-components'
import { NavMenuItem } from '../nav'

const List = Styled.ul`
  list-style-type: none;
`
const SectionHeader = Styled.h1`
  padding-block: var(--spc-s);
  padding-inline: calc(var(--spc-xs) + var(--spc-m));
  font-size: var(--txt-s);
  font-weight: bold;
`
const StyledNavMenuSection = Styled.div`
  padding-block: var(--spc-m);
`

const NavMenuSection = (props) => {
  return ( 
    <StyledNavMenuSection>
      <SectionHeader> { props.title } </SectionHeader>
      <List>
        { props.items.map((obj,idx) => <NavMenuItem key={idx} item={obj} />) }
      </List> 
    </StyledNavMenuSection>
  )
}

export default NavMenuSection
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'

import { useAlert } from '../hooks'
import { NavMenu } from '../nav'
import { Icon } from '../components'

const SignOutButton = Styled.button`
  padding: 8px;
  color: var(--clr-white);
  justify-content: center;
  background: var(--clr-2);
`
const StyledNav = Styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 200px;
  height: 100%;
  padding-top: var(--pdg-component);
  background-color: var(--clr-nav);
  color: var(--clr-white);
`

const Nav = () => {

  const navigate = useNavigate()
  const { handleError } = useAlert()

  const signOut = () => {
    Auth.signOut()
    .then(result => navigate('/'))
    .catch(error => handleError(error))
  }

  return (
    <StyledNav>
      <NavMenu />
      <SignOutButton onClick={signOut}>
        <p>Sign out</p>
      </SignOutButton>
    </StyledNav>
  )
}

export default Nav
export { default as ContextProvider } from './ContextProvider'
export { default as ErrorBoundary } from './ErrorBoundary'

export const Constant = {
  'reloadFreq': 300000,
  'resultLimit': 1000,
}

export const getVw = () => {
  return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
}

export const isMobile = () => {
  return getVw() < 480
}

export const randomInt = (min,max) => {
  return Math.floor( Math.random() * (max - min) + min )
}
import { useState, useEffect } from 'react'

const useDataFilter = (inputData) => {

  const [ data, setData ] = useState(inputData)

  useEffect(() => { setData(inputData) }, [inputData])

  const filterData = (value, path) => {
    setData(inputData.filter(obj => obj[path]().toLowerCase().includes(value.toLowerCase())))
  }

  return { data, filterData }
}

export default useDataFilter
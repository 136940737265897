import { House, Database, GraphUp, Person, Cloud } from 'react-bootstrap-icons'

import { NavMenuSection } from '../nav'

const sections = {
  '': [
    { icon: <House />, path: 'Dashboard' },
  ],
  'ANALYTICS': [
    { icon: <GraphUp />, path: 'Charts' },
    { icon: <Database />, path: 'Trends' },
  ],
  'RAWDATA': [
    { icon: <Person />, path: 'Clubs' },
    { icon: <Person />, path: 'Users' },
  ],
  'TOOLS': [
    { icon: <Cloud />, path: 'Backend' },
  ]
}

const NavMenu = (props) => {
  return (
    <div>
      { Object.keys(sections).map((obj,idx) =>
        <NavMenuSection 
          key={idx}
          title={obj}
          items={sections[obj]}
        />
      )}
    </div>
  )
}

export default NavMenu
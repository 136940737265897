import Styled from 'styled-components'

const TableHead = Styled.th`
  text-align: left;
  padding: var(--spc-s);
  border: none;
  color: var(--clr-white);
  background: var(--clr-1);
  white-space: nowrap;
  &:first-child {
    padding-left: calc(2*var(--spc-s));
  }
  &:last-child {
    padding-right: calc(2*var(--spc-s));
  }
`
const TableData = Styled.td`
  padding: var(--spc-s);
  text-align: left;
  white-space: nowrap;
  &:first-child {
    padding-left: calc(2*var(--spc-s));
  }
  &:last-child {
    padding-right: calc(2*var(--spc-s));
  }
`
const TableRow = Styled.tr`
  &:nth-child(odd) {
    background: var(--clr-fill-light);
  }
`
const StyledTable = Styled.table`
  overflow: hidden;
  padding: var(--spc-s);
  border-radius: var(--rds-component) var(--rds-component) 0 0;
  background: var(--clr-component);
  box-shadow: var(--sdw-component);
  border-collapse: collapse;
`

const Table = (props) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          { props.headers?.map((obj,idx) => 
            <TableHead key={idx}> 
              { obj } 
            </TableHead>
          )}
        </tr>
      </thead>
      <tbody>
        { props.data?.map(obj => 
          <TableRow key={obj.id}>
            { props.headers?.map((header,idx) =>
              <TableData key={idx}> 
                { obj[header] }
              </TableData>   
            )}
          </TableRow>
        )}
      </tbody>
    </StyledTable>
  ) 
}

export default Table
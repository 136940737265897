import { useNavigate } from 'react-router-dom'
import { ArrowBarLeft } from 'react-bootstrap-icons'
import Styled from 'styled-components'
import { Icon } from '../components'

const ExitButton = Styled.button`
  display: flex;
  white-space: nowrap;
  background: none;
  color: var(--clr-font);
`
const StyledPageHeader = Styled.header`
  display: flex;
  flex-wrap: wrap;
  gap: var(--pdg-control-l);
  overflow-y: scroll;
  align-items: center;
  justify-content: space-between;
  padding-block: var(--pdg-component);
  padding-inline: var(--pdg-container);
  padding-bottom: var(--pdg-component);
  border-bottom: 1px solid var(--clr-border);
`

const PageHeader = (props) => {

  const navigate = useNavigate()

  const exit = () => {
    if (props.exitTo) navigate(props.exitTo)
    else window.history.back()
  }

  const exitButton = 
    <ExitButton onClick={exit}>
      <Icon svg={<ArrowBarLeft />} type='font' />
      { props.exitTitle }
    </ExitButton>

  return (
    <StyledPageHeader>
      { props.exitTitle ? exitButton : null }
      <h1> { props.title } </h1>
      { props.tools }
    </StyledPageHeader>
  ) 
}

export default PageHeader
import { createContext, useState } from 'react'

const DURATION = 5000

export const AlertContext = createContext({ 
  message: '', 
  style: '',
  setAlert: () => {},
  handleError: () => {}, 
})

export const AlertProvider = (props) => {

  const [ message, setMessage ] = useState('')
  const [ style, setStyle ] = useState('')

  const setAlert = (message, style) => {
    setMessage(message)
    setStyle(style)
    setTimeout(() => {
      setMessage('')
      setStyle('')
    }, DURATION)
  }

  const handleError = (error) => {
    setAlert('Request failed', 'failure')
    console.warn(error)
  }

  const value = {
    message,
    style,
    setAlert,
    handleError
  }

  return (
    <AlertContext.Provider value={value}>
      { props.children }
    </AlertContext.Provider>
  )
}
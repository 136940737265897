import Styled from 'styled-components'
import { LogoSpinner } from '../assets'

const Image = Styled.img`
  width: 20%;
  max-width: 100px;
  animation: spin 2s infinite linear;
`
const StyledLoadingSpinner = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const LoadingSpinner = () => {
  return (
    <StyledLoadingSpinner>
      { <Image src={LogoSpinner} /> }
    </StyledLoadingSpinner>
  )
}

export default LoadingSpinner
import { createContext, useState } from 'react'
import { API } from 'aws-amplify'

import * as Query from '../api/queries'

import { useAlert } from '../hooks'
import { Club } from '../classes'
import { Constant } from '../utils'

export const ClubContext = createContext({ 
  clubs: null,
  listClubs: () => {},
  setClubs: () => {}
})

export const ClubProvider = (props) => {

  const { handleError } = useAlert()
  
  const [ clubs, setClubs ] = useState(null)
  const [ timeout, setTimeout ] = useState(Date.now())

  const listClubs = async () => {

    if (timeout > Date.now()) { return }

    setTimeout(Date.now() + Constant.reloadFreq)
    console.log('API: listing clubs')

    var clubs = []
    var nextToken = null
    var maxIter = 100

    try {
      do {
        console.log('Fetching clubs...')
        const result = await API.graphql({ 
          query: Query.listClubs, 
          variables: { limit: 500, nextToken: nextToken } 
        })
        clubs = clubs.concat(result.data.listClubs.items.map(obj => new Club(obj)))
        nextToken = result.data.listClubs.nextToken
        console.log('Successfully fetched clubs: ', clubs.length)
        maxIter = maxIter - 1
      } while (nextToken != null && maxIter > 0)
    }
    catch (error) {
      handleError(error)
    }

    setClubs(clubs.sort((x,y) => Club.sortByDate(x,y)))
  }

  const value = { 
    clubs,
    listClubs,
    setClubs
  }

  return (
    <ClubContext.Provider value={value}>
      { props.children }
    </ClubContext.Provider>
  )
}
import { useState, useEffect } from 'react'
import { Trend } from '../classes'
import { useAnalytics } from '../hooks'

const useTrends = () => {

  const { clubs, users } = useAnalytics()

  const [ cadence, setCadence ] = useState('weekly')
  const [ trends, setTrends ] = useState(null)

  useEffect(() => {

    if (!clubs || !users) return

    var timeFrames = []
    const today = new Date()
  
    if (cadence == 'weekly') {
      timeFrames = [new Date(2021,4,16,4)]
      while (timeFrames[0] < today) {
        var newDate = new Date(timeFrames[0])
        newDate.setDate(newDate.getDate()+7)
        timeFrames.unshift(newDate)
      }
    }
  
    if (cadence == 'monthly') {
      timeFrames = [new Date(2021,5,1,4)] 
      while (timeFrames[0] < today) {
        var newDate = new Date(timeFrames[0])
        newDate.setMonth(newDate.getMonth()+1)
        timeFrames.unshift(newDate)
      }
    }
  
    if (cadence == 'yearly') {
      timeFrames = [new Date(2021,11,31,4)]
      while (timeFrames[0] < today) {
        var newDate = new Date(timeFrames[0])
        newDate.setMonth(newDate.getMonth()+12)
        timeFrames.unshift(newDate)
      }
    }

    timeFrames[0] = today
    setTrends(timeFrames.map(obj => new Trend({ maxDate: obj }, clubs, users)))

  }, [clubs, users, cadence])

  return { trends, cadence, setCadence }
}

export default useTrends
import React from 'react'
import Styled from 'styled-components'

const iconTypes = {
  'custom': {
  },
  'default': {
    color: 'var(--clr-black)',
  },
  'font': {
    color: 'var(--clr-font)',
  },
  'nav': {
    color: 'var(--clr-white)',
  },
  'search': {
    color: 'var(--clr-border)',
  },
}

const IconContainer = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1%;
  max-height: var(--siz-control-s);
  aspect-ratio: 1;
  margin-block: auto;
`

const Icon = (props) => {
  return (
    <IconContainer>
      { React.cloneElement(props.svg, { ...iconTypes[props.type], size: '100%' }) }
    </IconContainer>
  )
}

export default Icon
import { Outlet } from 'react-router-dom'
import Styled from 'styled-components'

import { Nav } from '../nav'

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-width: calc(100% - 200px);
  background: var(--clr-container);
`
const StyledLayout = Styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`

const Layout = () => {
  return (
    <StyledLayout>
      <Nav />
      <Container>
        <Outlet />
      </Container>
    </StyledLayout>
  ) 
}

export default Layout
import Styled from 'styled-components'

const StyledControlStack = Styled.div`
  display: flex;
  gap: ${props => props.gap};
`

const ControlStack = (props) => {
  return (
    <StyledControlStack gap={props.gap}>
      { props.children }
    </StyledControlStack>
  ) 
}

export default ControlStack
import PropTypes from 'prop-types'

import { AlertProvider } from '../contexts/AlertContext'
import { ClubProvider } from '../contexts/ClubContext'
import { UserProvider } from '../contexts/UserContext'
import { AnalyticsProvider } from '../contexts/AnalyticsContext'

const ContextProvider = (props) => {

  return (
    <AlertProvider>
        <ClubProvider>
          <UserProvider>
            <AnalyticsProvider>
              { props.children }
            </AnalyticsProvider>
          </UserProvider>
        </ClubProvider>
    </AlertProvider>
  )
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContextProvider
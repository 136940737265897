import PropTypes from 'prop-types'
import Styled from 'styled-components'
import { Icon } from '../components'

const StyledButton = Styled.button`
  transition: var(--hvr-transition);
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width ? props.width : null};
  aspect-ratio: ${props => props.square ? '1' : null};
  opacity: ${props => props.disabled ? '20%' : '100%'};
  white-space: nowrap;
  border-radius: var(--rds-control);
  box-shadow: var(--sdw-component);
  font-weight: bold;
  &:hover {
    transform: var(--hvr-transform);
  } 
` 
StyledButton.primary = {
  background: 'var(--clr-1)',
  color: 'var(--clr-white)',
}
StyledButton.secondary = {
  background: 'var(--clr-2)',
  color: 'var(--clr-white)',
}
StyledButton.tertiary = {
  background: 'var(--clr-gray-4)',
  color: 'var(--clr-font)',
}
StyledButton.l = {
  height: 'var(--siz-control-l)',
  gap: 'var(--spc-s)',
  paddingInline: 'var(--pdg-control-l)',
}
StyledButton.m = {
  height: 'var(--siz-control-m)',
  gap: 'var(--spc-xs)',
  padding: 'var(--pdg-control-m)',
}
StyledButton.s = {
  height: 'var(--siz-control-s)',
  gap: 'var(--spc-xxs)',
  padding: 'var(--pdg-control-s)',
  fontSize: 'var(--txt-s)',
}

const Button = (props) => {

  const style = { 
    ...StyledButton[props.type], 
    ...StyledButton[props.size], 
    width: props.width
  }

  const title = (props.icon || props.icon2) && window.matchMedia('(max-width: 720px)').matches ? null : props.title

  return (
    <StyledButton 
      onClick={props.onClick} 
      style={style} 
      disabled={props.disabled}
      square={(props.icon || props.icon2) && !title}
    >
      { props.icon ? <Icon type={props.type} svg={props.icon} /> : null }
      { title }
      { props.icon2 ? <Icon type={props.type} svg={props.icon2} /> : null }
    </StyledButton>
  ) 
}

Button.propTypes = {
  icon: PropTypes.element,
  icon2: PropTypes.element,
  title: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'cancel']),
  onClick: PropTypes.func.isRequired,
}

Button.defaultProps = {
  icon: null,
  icon2: null,
  title: null,
  size: 'l',
  type: 'primary',
}

export default Button
import { NavLink } from 'react-router-dom'
import Styled from 'styled-components'
import { Icon } from '../components'

const Selector = Styled.div`
  height: 100%;
  width: var(--spc-xs);
`
const Title = Styled.h2`
  font-size: var(--txt-m);
  font-weight: normal;
`
const StyledLink = Styled(NavLink)`
  display: flex;
  gap: var(--pdg-control-l);
  align-items: center;
  padding-right: var(--pdg-control-l);
  height: 100%;
  width: 100%;
  color: var(--clr-white);
  &.active {
    background: linear-gradient(90deg, var(--clr-2) -100%, var(--clr-nav) 100%);
  }
  &.active ${Selector} {
    background: var(--clr-2);
  }
`
const StyledNavMenuItem = Styled.li`
  height: calc(2*var(--txt-l));
  &:hover {
    background: linear-gradient(90deg, var(--clr-2) -100%, var(--clr-nav) 100%);
  }
  &:hover ${Selector} {
    background: var(--clr-2);
  }
`

const NavMenuItem = (props) => {

  return (
    <StyledNavMenuItem>
      <StyledLink to={props.item.path}>
        <Selector />
        <Icon type='nav' svg={props.item.icon} />
        { props.minimized ? null : <Title> { props.item.path } </Title> }
      </StyledLink>
    </StyledNavMenuItem>
  )
}

export default NavMenuItem